import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/jonespen/projects/urbaninfrastructure/react-ui-kit/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from "docz";
import { Dot } from "@urbaninfrastructure/react-ui-kit";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "dot"
    }}>{`Dot`}</h1>
    <p>{`Used to indicate some kind of state.`}</p>
    <h2 {...{
      "id": "default"
    }}>{`Default`}</h2>
    <Playground __position={0} __code={'<Dot />\n<Dot bg=\"palette.red01\">Unavailable</Dot>\n<Dot bg=\"palette.green01\">Available</Dot>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Dot,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Dot mdxType="Dot" />
  <Dot bg="palette.red01" mdxType="Dot">Unavailable</Dot>
  <Dot bg="palette.green01" mdxType="Dot">Available</Dot>
    </Playground>
    <h2 {...{
      "id": "component-props"
    }}>{`Component props`}</h2>
    <Props of={Dot} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      